import React from 'react'
import './Footer.css';
import logo from "./Pages/Images/logofirstAD.png"

const Footer = () => {
    return (
        <div>
        <footer className="bg-light text-center text-lg-start">

       
      
        <div className="text-center p-3 foot">
        <div className="col-lg-12 col-sm-12 mx-auto d-block">
        <h5 className="text-uppercase">
          <img src={logo} height="60vw" width="40vw"></img>
        </h5>
  
        <p className="text-center">
          Revolutionizing Market
        </p>
        <div className="container">
        <br/>
        <div className="row">
	 <div className="col-lg-12 col-sm-12">
<a href="https://www.facebook.com/yourFirstAd" target="_blank"> <i className="fa fa-facebook"></i></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href="https://www.instagram.com/yourfirstad/" target="_blank"><i className="fa fa-instagram"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href="https://www.linkedin.com/company/yourfirstad/" target="_blank"><i className="fa fa-linkedin"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href="https://yourfirstad.com/" target="_blank"><i className="fa fa-globe"></i></a>
  </div>
    </div>
  <br/>

</div>
      </div>
          © 2020 Copyright:
          <a className="text-white" href="">&nbsp;&nbsp;yourFirstAd.com</a>
        </div>
      </footer>
        </div>
    )
}

export default Footer
