import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBkjf-oUYFqzYmLwfRmkhzUCvYQU5ACWQ4",
    authDomain: "yourfirstad-shop-e7782.firebaseapp.com",
    projectId: "yourfirstad-shop-e7782",
    storageBucket: "yourfirstad-shop-e7782.appspot.com",
    messagingSenderId: "204268790200",
    appId: "1:204268790200:web:6de34401de25920276af4d"
});

var db = firebaseApp.firestore();
var auth = firebase.auth();
var storage = firebase.storage()

export {db,auth,storage};