import React from "react";
import { auth } from "../../Firebase";

import "./login.styles.css";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email.length !== 0 && password.length !== 0) {
      auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          this.props.history.push("/admin");
        })
        .catch((err) => {
          window.alert(err);
          document.getElementById("admin-login-form").reset();
        });
    }
  };

  render() {
    return (
      <div
        className="login container d-flex flex-column align-items-center justify-content-start"
        style={{ width: "100%", height: "80vh", marginTop: "100px" }}
      >
        <h1 style={{ marginBottom: "40px" }}>Login</h1>

        <form id="admin-login-form">
          <div class="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              name="email"
              onChange={(e) => this.handleInput(e)}
            />
            <small id="emailHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              name="password"
              onChange={(e) => this.handleInput(e)}
            />
          </div>
          <button
            onClick={(e) => this.handleSubmit(e)}
            style={{ border: "none" }}
            type="submit"
            class="btn btn-primary"
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default Login;
