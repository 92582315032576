import React, { useState } from "react";
import { auth } from "../Firebase";
import "../Navbar/Navbar.css";
import logo from "../Pages/Images/logofirstAD.png";

const Navbar = () => {
  const [showLogout, setShowLogout] = useState(false);
  const [adminEmail, setAdminEmail] = useState(null);

  auth.onAuthStateChanged((user) => {
    if (user) {
      setShowLogout(true);
      setAdminEmail(user.email);
    }
  });

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark navs">
        <div className="container-fluid cont">
          <a className="navbar-brand" href="https://yourfirstad.com">
            <img className="img-fluid" src={logo} width="37vw" alt="" />
            <span className="ml-4">yourFirstAd</span>
          </a>
        </div>
        <button
          className="navbar-toggler ham__btn"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item ">
              <a className="nav-link text-white ml-4" href="/">
                <i className="fa fa-home mr-2"></i>Home
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link text-white ml-4" href="#cards">
                <i className="fa fa-shopping-cart mr-2"></i>Shop
              </a>
            </li>
           

            {showLogout === true ? (
              <li
                className="nav-item"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    window.confirm(`You will be Signed Out from ${adminEmail}!`)
                  ) {
                    auth.signOut();
                    if (window.location.pathname !== "/admin") {
                      window.location.reload();
                    }
                  }
                }}
              >
                <a className="nav-link text-white ml-4" href="">
                  <i className="fa fa-sign-out mr-2"></i>Logout
                </a>
              </li>
            ) : null}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
