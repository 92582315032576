import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Home.css";
import Card from "../Card";
import img1 from "../Pages/Images/b1.svg";
import Footer from "../Footer";
import Typewriter from "typewriter-effect";

const Home = () => {
  return (
    <div className="home__main">
      <Navbar />
      <div className="">
        <img
          className="header-custom img-fluid"
          width="1300vw"
          src={img1}
          alt=""
        ></img>
      </div>
      <div className="home__text text-white">
        <Typewriter
          className="type-writer"
          options={{
            strings: ["ONLINE SHOPPING ..."],
            autoStart: true,
            loop: true,
          }}
        />
        <hr  style={{width: "50%", height: "0.5vw", backgroundColor: "#CB96FE" }}/>
        <br />
        <p className="container-fluid text-white">
          We understand how troublesome navigating through online stores can be
          incertitude regarding the product's standard, distressing about the
          reliability of brands. <br/><br/> To succor you through these dilemmas,
          yourFirstAd has assembled the top of the range brands for you so that
          you may wend your way through the choicest option without any fret!{" "}
        </p>

        <button className="btn shopBtn">Shop Now</button>
      </div>
      <br />
      <div id="cards">
        <h1 className="text-center headline text-white">Get Your Products Now!</h1>
        <hr
          style={{ width: "20%", height: "0.5vw", backgroundColor: "#CB96FE" }}
        />{" "}
        <br /> <br />
      </div>
      <div>
        <Card />

        <Footer />
      </div>
    </div>
  );
};

export default Home;
