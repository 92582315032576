import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Admin from "./Pages/Admin";
import Login from "./Pages/login/login.component";
import AdminEdit from "./Pages/admin-edit/admin-edit";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/admin" exact component={Admin} />
        <Route path="/login" exact component={Login} />
        <Route path="/admin-edit" exact component={AdminEdit} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
