import React, { useState } from "react";
import "./Admin.css";
import { auth, db, storage } from "../Firebase";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";

const Admin = (props) => {
  const [myName, setMyName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [originalLink, setOriginalLink] = useState("");
  const [refInpObj, setRefInpObj] = useState({
    referralCode: "",
    refDiscount: "",
  });
  const [referralInputs, setReferralInputs] = useState([refInpObj]);

  auth.onAuthStateChanged((user) => {
    if (user) {
    } else {
      props.history.push("/login");
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("card-deatils")
              .add({
                productName: myName,
                productDescription: description,
                Price: price,
                url: url,
                instamojoLink: originalLink,
                referralCodes: referralInputs,
              })
              .then(() => {
                alert("Details has been submitted");
              })
              .catch((error) => {
                alert(error.message);
              });

            setMyName("");
            setDescription("");
            setPrice("");
            setOriginalLink("");
            setReferralInputs([refInpObj]);
          });
      }
    );
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const addMoreRefInput = () => {
    setReferralInputs([...referralInputs, refInpObj]);
  };

  const referralCodeDetails = (e, idx) => {
    const { name, value } = e.target;
    const temp = referralInputs.map((inp, i) => {
      return idx == i ? Object.assign({}, inp, { [name]: value }) : inp;
    });

    setReferralInputs(temp);
  };

  const removeThatRefInp = (index) => {
    const filteredRefCodes = [...referralInputs];
    filteredRefCodes.splice(index, 1);
    setReferralInputs(filteredRefCodes);
  };

  return (
    <div className="div__main">
      <Navbar />
      <div class="card text-center mx-auto mt-5 card__main mb-5 container-fluid">
        <div class="card-header text-white header__main">Card Details</div>
        <form class="card-body" onSubmit={handleSubmit}>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Name
            </label>
            <input
              type="text"
              class="form-control"
              value={myName}
              onChange={(e) => setMyName(e.target.value)}
              id="name"
              placeholder="Product Name"
              required
            ></input>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Short Description
            </label>
            <input
              type="text"
              class="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              placeholder="short description"
              required
            ></input>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Price(INR)
            </label>
            <input
              type="text"
              class="form-control"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              id="price"
              placeholder="price"
              required
            ></input>
          </div>

          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Original Product Link
            </label>
            <input
              type="text"
              class="form-control"
              value={originalLink}
              onChange={(e) => setOriginalLink(e.target.value)}
              id="originalLink"
              placeholder="Instamojo link of product"
              required
            ></input>
          </div>

          <div className="mb-4">
            {referralInputs.map((refInp, index) => (
              <div className="form-row">
                <div class="mb-3 col-6">
                  {index == 0 ? (
                    <label for="exampleFormControlInput1" class="form-label">
                      {referralInputs.length === 1
                        ? "Referral Code"
                        : "Referral Codes"}
                    </label>
                  ) : null}
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => referralCodeDetails(e, index)}
                    id="refferalCode"
                    value={refInp.referralCode}
                    name="referralCode"
                    placeholder={`Referral Code ${index + 1}`}
                    required
                  ></input>
                </div>
                <div class="mb-3 col-5">
                  {index == 0 ? (
                    <label for="exampleFormControlInput1" class="form-label">
                      Discount
                    </label>
                  ) : null}
                  <input
                    type="number"
                    class="form-control"
                    onChange={(e) => referralCodeDetails(e, index)}
                    id="refDiscount"
                    name="refDiscount"
                    value={refInp.refDiscount}
                    placeholder="Discount %"
                    required
                    min="1"
                    max="100"
                  ></input>
                </div>
                <div
                  style={{
                    marginTop: "7px",
                    marginLeft: "10px",
                    color: "red",
                  }}
                  className={`row align-items-${
                    index == 0 ? "center" : "start"
                  }`}
                  onClick={() => removeThatRefInp(index)}
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
              </div>
            ))}
          </div>

          <div className="form-row" style={{ margin: "3px 2px 20px" }}>
            <div className="col"></div>
            <div className="col"></div>
            <div className="col">
              <button
                onClick={addMoreRefInput}
                type="button"
                class="btn btn-primary"
              >
                + Add more Referral Codes
              </button>
            </div>
            <div className="col"></div>
            <div className="col"></div>
          </div>

          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Upload Image
            </label>
            <br />
            <span class="badge badge-primary pull-left">
              Should not be greater than 300px
            </span>{" "}
            <br />
            <input
              type="file"
              class="form-control"
              id="upload"
              placeholder="Image Upload"
              required
              onChange={handleChange}
            ></input>{" "}
          </div>

          <button type="submit" class="btn container">
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Admin;
