import React, { useEffect, useState } from "react";
import { auth, db } from "../../Firebase";

import "./admin-edit.css";

const AdminEdit = (props) => {
  //Product details Array
  const [products, setProducts] = useState([]);

  const [productToBeEdited, setProductToBeEdited] = useState("");

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    setEditProductObject({ ...editProductObject, [name]: value });
  };

  const editRefCodes = (index, e) => {
    const { value, name } = e.target;
    const temp = editProductObject.referralCodes.map((code, idx) => {
      return idx === index ? Object.assign({}, code, { [name]: value }) : code;
    });

    setEditProductObject({ ...editProductObject, referralCodes: temp });
  };

  const [editProductObject, setEditProductObject] = useState({
    Price: 0,
    instamojoLink: "",
    productDescription: "",
    productName: "",
    url: "",
    referralCodes: [],
  });

  //Check if admin Logged In or not
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        props.history.push("/login");
      }
    });
  }, [props.history]);

  //Fetch all products
  useEffect(() => {
    db.collection("card-deatils").onSnapshot(({ docs: products }) => {
      if (products.length) {
        const temp = [];
        products.map((product) => {
          temp.push({ ...product.data(), id: product.id });
        });
        setProducts([...temp]);
      }
    });
  }, []);

  const removeThatRefInp = (index) => {
    const filteredRefCodes = [...editProductObject.referralCodes];
    filteredRefCodes.splice(index, 1);
    setEditProductObject({
      ...editProductObject,
      referralCodes: filteredRefCodes,
    });
  };

  const addMoreRefInput = () => {
    setEditProductObject({
      ...editProductObject,
      referralCodes: [
        ...editProductObject.referralCodes,
        {
          refDiscount: "",
          referralCode: "",
        },
      ],
    });
  };

  const handleChangeInProduct = () => {
    const isUnfillled = editProductObject.referralCodes.filter((refs) => {
      return !parseInt(refs.refDiscount) || refs.referralCode.length === 0;
    });

    if (isUnfillled.length !== 0) {
      window.alert("Please fill all the Referral Codes!");
    } else {
      if (window.confirm("Are you sure?")) {
        db.collection("card-deatils")
          .doc(editProductObject.id)
          .update({
            Price: editProductObject.Price,
            instamojoLink: editProductObject.instamojoLink,
            productName: editProductObject.productName,
            referralCodes: editProductObject.referralCodes,
            url: editProductObject.url,
            productDescription: editProductObject.productDescription,
          })
          .then(() => {
            window.alert("Successfully Updated!");
          });
      }
    }
  };

  return (
    <div className="admin-edit">
      <div className="container-fluid">
        <div className="text-center edit-product-heading">
          Edit Product Details
        </div>
        <div className="row wrap justify-content-center">
          {products.length ? (
            products.map((product) => (
              <div
                key={product.id}
                className="card col-md-3 product-card"
                style={{}}
              >
                <img
                  src={product.url}
                  className="card-img-top product-img"
                  alt="product"
                />
                <div className="card-body">
                  <h5 className="card-title product-title">
                    {product.productName}
                  </h5>
                  <p className="card-text product-description">
                    {product.productDescription}
                  </p>
                  <button
                    type="button"
                    className="btn container-fluid mx-auto"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    className="btn btn-primary edit-btn"
                    onClick={() => {
                      setProductToBeEdited(product);

                      setEditProductObject({
                        productName: product.productName,
                        Price: product.Price,
                        productDescription: product.productDescription,
                        instamojoLink: product.instamojoLink,
                        url: product.url,
                        referralCodes: product.referralCodes,
                        id: product.id,
                      });
                    }}
                  >
                    Edit Details
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="edit-admin-loading">Loading...</div>
          )}
        </div>

        <div
          className="modal fade edit-modal"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Details
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Product Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="productName"
                      value={editProductObject.productName}
                      onChange={(e) => {
                        handleEditChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">
                      Description:
                    </label>
                    <textarea
                      name="productDescription"
                      value={editProductObject.productDescription}
                      className="form-control"
                      id="message-text"
                      onChange={(e) => {
                        handleEditChange(e);
                      }}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label htmlFor="Price" className="col-form-label">
                      Price:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Price"
                      name="Price"
                      value={editProductObject.Price}
                      onChange={(e) => {
                        handleEditChange(e);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="instamojoLink" className="col-form-label">
                      Instamojo Link:
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="instamojoLink"
                      name="instamojoLink"
                      value={editProductObject.instamojoLink}
                      onChange={(e) => {
                        handleEditChange(e);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="url" className="col-form-label">
                      Url:
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="url"
                      name="url"
                      value={editProductObject.url}
                      onChange={(e) => {
                        handleEditChange(e);
                      }}
                    />
                  </div>

                  {editProductObject.referralCodes.map((ref, index) => (
                    <div key={index} className="form-row">
                      <div className="mb-3 col-6">
                        {index === 0 ? (
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Referral
                          </label>
                        ) : null}
                        <input
                          value={ref.referralCode}
                          type="text"
                          className="form-control"
                          id="refferalCode"
                          name="referralCode"
                          placeholder={`Referral Code ${index + 1}`}
                          required
                          onChange={(e) => {
                            editRefCodes(index, e);
                          }}
                        ></input>
                      </div>
                      <div className="mb-3 col-5">
                        {index === 0 ? (
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Discount
                          </label>
                        ) : null}
                        <input
                          value={ref.refDiscount}
                          type="number"
                          className="form-control"
                          id="refDiscount"
                          name="refDiscount"
                          placeholder="Discount %"
                          min="1"
                          max="100"
                          onChange={(e) => {
                            editRefCodes(index, e);
                          }}
                          required
                        />
                      </div>
                      <div
                        style={{
                          marginTop: "7px",
                          marginLeft: "10px",
                          color: "red",
                        }}
                        className={`row align-items-${
                          index == 0 ? "center" : "start"
                        }`}
                        onClick={() => removeThatRefInp(index)}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </div>
                    </div>
                  ))}

                  <div className="form-row" style={{ margin: "3px 2px 20px" }}>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col">
                      <button
                        onClick={addMoreRefInput}
                        type="button"
                        className="btn btn-primary"
                      >
                        + Add more Referral Codes
                      </button>
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditProductObject({ ...productToBeEdited });
                  }}
                >
                  Reset Details
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleChangeInProduct()}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEdit;
