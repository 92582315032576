import React, { useEffect, useRef, useState } from "react";
import "./Card.css";
import { db } from "./Firebase";

const Card = () => {
  const [product, setProduct] = useState([]);
  const [myEmail, setMyEmail] = useState("");
  const [referral, setReferral] = useState("");
  const [instaMojoLink, setInstaMojoLink] = useState("");
  const [pname, setPname] = useState("");
  const [pDescription, setPDescription] = useState("");
  const [pPrice, setPPrice] = useState("");
  const [pImage, setPImage] = useState("");

  // Referral States
  const [refCodes, setRefCodes] = useState("");

  const productPriceRef = useRef();

  useEffect(() => {
    db.collection("card-deatils").onSnapshot((snapshot) => {
      setProduct(
        snapshot.docs.map((doc) => ({
          post: doc.data(),
          id: doc.id,
        }))
      );
    });
  }, []);

  const handleChange = () => {
    if (myEmail.length === 0 || referral.length === 0) {
      window.alert("Please enter all fields!");
    } else if (!myEmail.includes("@")) {
      alert("Please enter a valid email address!");
      document.getElementById("refF").value = "";
      document.getElementById("emailF").value = "";
    } else {
      const appliedReferralCode = refCodes.filter(
        (refCode) =>
          refCode.referralCode.toLowerCase() === referral.toLowerCase()
      );

      if (appliedReferralCode.length === 0) {
        window.alert("Referral Code is Invalid!");
        document.getElementById("refF").value = "";
      document.getElementById("emailF").value = "";
      } else {
        const refData = appliedReferralCode[0];

        const price = parseInt(pPrice);
        const dis = parseInt(refData.refDiscount);
        productPriceRef.current.innerHTML = `<span style="color:white">₹${price} - ${dis}% = ₹${
          price - price * (dis / 100)
        }</span><span  class="ref-added-msg">*referral code applied*</span> `;

        db.collection("user-shopping details")
          .add({
            User_Email: myEmail,
            referral_Code: referral.toLowerCase(),
            Product_Name: pname,
            discount: refData.refDiscount,
          })
          .then(() => {
            document.getElementById("step2").style.display = "block";
            document.getElementById("step1").style.display = "none";
          })
          .catch((error) => {
            alert(error.message);
          });
      }
    }
  };
  return (
    <div className="row row-cols-1 row-cols-md-12 g-4 mx-auto mb-5">
      {product.map(({ post }) => (
        <div key={post} className=" card mx-auto my-card conatiner ">
          <div class="">
            <div class="row g-0">
              <div class="col-md-4">
                <img
                  className="img-fluid img__1"
                  src={post.url}
                  alt="..."
                ></img>
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h2 class="card-title my-title">{post.productName}</h2>
                  <p class="card-text my-description">
                    {post.productDescription}
                  </p>
                  {/* <p class="card-text my-price">₹{post.Price}</p>*/}
                  <button
                    style={{ width: "50%", color: "purple", fontWeight: "900" }}
                    type="button"
                    className="btn container-fluid mx-auto"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={() => {
                      productPriceRef.current.innerHTML = `<span>SubTotal :- &nbsp; ₹${post.Price}</span>`;
                      document.getElementById("step1").style.display = "block";
                      document.getElementById("step2").style.display = "none";

                      setInstaMojoLink(post.instamojoLink);
                      setPPrice(post.Price);
                      setPDescription(post.productDescription);
                      setPImage(post.url);
                      setPname(post.productName);
                      setRefCodes(post.referralCodes);
                    }}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <h1 className="text-center text-white">Product Details</h1>
        <hr
          className="mx-auto"
          style={{ width: "15%", height: "0.5vw", backgroundColor: "#CB96FE" }}
        />

        <button
          type="button"
          className="close"
          style={{ fontSize: "4rem" }}
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="card card-inside" style={{ margin: "5vw" }}>
          <div className="row m-3">
            <div className="mx-auto" style={{ background: "transparent" }}>
              <img
                src={pImage}
                className="card-img-top img__1 img-fluid"
                alt="..."
                style={{width:"100%",marginRight:"auto",marginLeft:"auto"}}
              ></img>
              <h3 className="card-title text-center text-white">{pname}</h3>

              <div className="card-body">
                {/*<p className="card-text">{pDescription}</p> */}
                <hr />
                <p
                  className="text-white"
                  style={{ display: "flex", flexDirection: "column" }}
                  ref={productPriceRef}
                >
                  SubTotal :- &nbsp; ₹{pPrice}{" "}
                </p>
              </div>
            </div>
            <div className="modal-dialog" role="document">
              <div
                className="modal-content mx-auto"
                style={{ background: "transparent", border: "none" }}
              >
                <div className="modal-body modal-lg">
                  <div></div>
                </div>
                <div className="modal-body  mx-auto">
                  <form action="">
                    <input
                    id="emailF"
                      className="form-control mb-5"
                      required
                      placeholder="Email address"
                      type="email"
                      value={myEmail}
                      onChange={(e) => setMyEmail(e.target.value)}
                      style={{
                        background: "transparent",
                        borderRadius: "50px 50px 50px 50px",
                        padding: "2vw",
                        color: "white",
                      }}
                    />
                    <input
                    id="refF"
                      className="form-control mb-5"
                      required
                      style={{
                        background: "transparent",
                        borderRadius: "50px 50px 50px 50px",
                        padding: "2vw",
                        color: "white",
                      }}
                      placeholder="referral code"
                      type="text"
                      value={referral}
                      onChange={(e) => setReferral(e.target.value)}
                    />

                    <div className="mx-auto">
                      <a>
                        <button
                          id="step1"
                          type="button"
                          onClick={handleChange}
                          className="btn btn-light"
                          style={{
                            borderRadius: "50px 50px 50px 50px",
                            color: "purple",
                            fontWeight: "900",
                          }}
                        >
                          Confirm
                        </button>
                      </a>
                      <a
                        id="step2"
                        className="btn btn-light"
                        href={instaMojoLink}
                        style={{
                          borderRadius: "50px 50px 50px 50px",
                          color: "purple",
                          fontWeight: "900",
                        }}
                      >
                        Pay Now
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
